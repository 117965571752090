import { ref, reactive, computed, onMounted } from 'vue';
import * as API from '@/API/checking/spotChecking';
import useThousandsAndTwoDecimal from '@/utils/payment/useThousandsAndTwoDecimal';
import useThousands from '@/utils/payment/useThousands';
import moment from 'moment';
import { Moment } from 'moment';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import downloadFile from '@/utils/payment/downloadFile';
import { message } from 'ant-design-vue';

type SearchParams = {
    year: Moment | null;
    month: Moment | null;
};

export interface TableData {
    payoutRoundId: string;
    payoutRound: string;
    programCount: string;
    dealerCount: string;
    vinCount: string;
    amountWVatTotal: string;
    amountWoVatTotal: string;
    status: string;
    lastUpdateDate: string;
}

const useVehicleNameSet = () => {
    const { commit } = useStore();
    const router = useRouter();

    const searchParams = reactive<SearchParams>({
        year: null,
        month: null,
    });
    const total = ref<number>(0);
    const pageNumber = ref<number>(0);
    const pageSize = ref<number>(10);
    const columnsData = computed(() => {
        return [
            { title: "Payout Round", dataIndex: "payoutRound",slots: { customRender: 'payoutRound' },width: 250, },
            { title: "Program No.", dataIndex: "programCount", customRender: useThousands(), align: 'right', width: 100 },
            { title: "Dealer No.", dataIndex: "dealerCount", customRender: useThousands(), align: 'right', width: 80 },
            { title: "VIN No.", dataIndex: "vinCount", customRender: useThousands(), align: 'right', width: 100 },
            { title: "Amount w VAT", dataIndex: "amountWVatTotal", customRender: useThousandsAndTwoDecimal(), width: 190, align: "right"},
            { title: "Amount w/o VAT", dataIndex: "amountWoVatTotal", customRender: useThousandsAndTwoDecimal(), width: 190, align: "right"},
            { title: "Status", dataIndex: "status", align: 'center',width: 300,},
            { title: "Last Update Date", dataIndex: "lastUpdateDate", slots: { customRender: 'lastUpdateDate' }, width: 100},
            { title: "Operation", dataIndex: "operation",slots: { customRender: 'operation' }, align: 'center', width: 100 }
        ]
    })
    const tableData = ref<TableData[]>()
    const getTableData = () => {
        // pendingOrReview:9 review
        const params = {
            pageIndex: pageNumber.value,
            pageSize: pageSize.value,
            pendingOrReview: 9,
            roundMonth: searchParams.month
                ? searchParams.month.format('M')
                : '',
            roundYear: searchParams.year
                ? searchParams.year.format('YYYY')
                : '',
        };
        API.getReviewPageList(params).then((data: any) => {
            tableData.value = data.content;
            total.value = data.totalElements;
        });
    };
    const doSearch = () => {
        pageNumber.value = 1;
        getTableData();
    };
    const pageChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1;
        pageSize.value = size;
        getTableData();
    };
    const sizeChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1;
        pageSize.value = size;
        getTableData();
    };
    const payoutRoundClick = (record: any) => {
        if (record.type !== 'DEALER') return;
        const info = {
            payoutRoundId: record.payoutRoundId,
            payoutRound: record.payoutRound,
            status: record.status,
        };
        console.log(info);
        commit('spotChecking/updatePayoutRoundData', info);
        router.push({ path: '/spotChecking/pendingApproval/payoutRound' });
    };
    const downloadClick = (record: TableData) => {
        const params = {
            url:
                '/claimapi/pendingApprovalAndReview/export?payoutRound=' +
                record.payoutRoundId,
            method: 'get',
        };
        downloadFile(params).catch((msg) => message.warning(msg));
    };
    const getSearchParams = () => {
        API.getDefaultYearAndMonth().then((data: any) => {
            searchParams.year = moment(data.year.toString());
            // searchParams.month = moment(data.year+'-'+(data.month>10?data.month:'0'+data.month))
            doSearch();
        });
    };
    const doReset = () => {
        Object.assign(searchParams, {
            year: null,
            month: null,
        });
        tableData.value = [];
        getSearchParams();
    };
    onMounted(() => {
        getSearchParams();
    });
    return {
        searchParams,
        columnsData,
        tableData,
        doSearch,
        doReset,
        pageChange,
        sizeChange,
        pageSize,
        pageNumber,
        total,
        payoutRoundClick,
        downloadClick,
    };
};
export default useVehicleNameSet;
