
import { defineComponent, ref } from 'vue';
import useApprovalReview from '@/hooks/checking/spotChecking/pendingApproval/useApprovalReview';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
type Key = ColumnProps['key'];
const tableWidth = window.innerWidth;
const tableHeight = window.innerHeight - 420;
import TableWrapper from '@/components/TableWrapper.vue';
import YearPicker from '@/views/Payment/components/YearPicker.vue';
import { useStore } from 'vuex';
export default defineComponent({
    name: 'payoutPreparation',
    components: {
        // Pagination,
        TableWrapper,
        YearPicker,
    },
    props: {},
    setup() {
        const store = useStore();
        const {
            searchParams,
            doReset,
            columnsData,
            tableData,
            doSearch,
            pageChange,
            sizeChange,
            pageSize,
            pageNumber,
            total,
            payoutRoundClick,
            downloadClick,
        } = useApprovalReview();
        const handlePageChange = (page: number, pageSize: number) => {
            pageChange(page, pageSize);
        };
        const handleSizeChange = (page: number, pageSize: number) => {
            sizeChange(page, pageSize);
        };

        return {
            store,
            searchParams,
            doSearch,
            doReset,
            tableWidth,
            tableHeight,
            columnsData,
            tableData,
            pageSize,
            pageNumber,
            total,
            handlePageChange,
            handleSizeChange,
            payoutRoundClick,
            downloadClick,
        };
    },
});
